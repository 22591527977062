import { Injectable } from '@angular/core';
import * as geohash from 'ngeohash';

@Injectable()
export class GeoService {


  // tslint:disable-next-line:no-empty
  constructor() {
  }

  public fromLatLngToGeoHash(lat: number, lng: number): string {
    return geohash.encode(lat, lng)
  }

  public fromGeohashToLatLng(h: string) {
    return geohash.decode(h);
  }

  public fromGeoHashToNeighbors(gh: string): any {
    let neighbors = geohash.neighbors(gh);
    return neighbors;
  }

  public fromGeoHashToPoly(gh: string): any {
    let vertices = <any>[];
    let x = geohash.decode_bbox(gh);
    vertices.push({
      lat: x[0],
      lng: x[1]
    });
    vertices.push({
      lat: x[2],
      lng: x[1]
    });
    vertices.push({
      lat: x[2],
      lng: x[3]
    });
    vertices.push({
      lat: x[0],
      lng: x[3]
    });
    return vertices;
  }

  public isGeohash(geo: string): boolean {
    let result = false;
    console.log(geo);
    if (geo && geo.length == 4) {
      let re = /([0123456789bcdefghjkmnpqrstuvwxyz])/g;
      let g = geo.match(re);
      if (g && g.length == 4) {
        result = true;
      }
    }
    return result;
  }

  public toLL(key: string): string {
    let r = ''; //mgrs.toPoint('30T XQ 93 70');
    return r;
  }

  public calcCrow(g1: string, g2: string) {
    var R = 6371; // km
    // var dLat = this.toRad(lat2-lat1);
    var dLat = this.toRad(this.fromGeohashToLatLng(g2).latitude - this.fromGeohashToLatLng(g1).latitude);
    // var dLon = this.toRad(lon2-lon1);
    var dLon = this.toRad(this.fromGeohashToLatLng(g2).longitude - this.fromGeohashToLatLng(g1).longitude);
    // var lat1 = this.toRad(lat1);
    // var lat2 = this.toRad(lat2);
    var lat1 = this.toRad(this.fromGeohashToLatLng(g1).latitude);
    var lat2 = this.toRad(this.fromGeohashToLatLng(g2).latitude);

    var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    var d = R * c;
    return d;
  }

  public toRad(Value) {
      return Value * Math.PI / 180;
  }


}
